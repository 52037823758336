class WebNotification {
  static tagCounter = 0;
  NotificationAPI =  window.Notification || global.Notification
  constructor() {
    const NotificationAPI = window.Notification || global.Notification
    this.lib = NotificationAPI;
    this.allowRequest = true;
  }

  static initWebNotificationFromContext(context) {
    return new WebNotification(context);
  }

  get permissionGranted() {
    const permission = this.lib.permission;
    return permission === 'granted';
  }

  static isEnabled() {
    return this.permissionGranted;
  }

  static createAndDisplayNotification(title, options, callback) {
    let autoClose = 0;
    if (options.autoClose && typeof options.autoClose === 'number') {
      autoClose = options.autoClose;
    }

    if (!options.icon) {
      options.icon = '/favicon.ico';
    }

    const onNotification = (notification) => {
      if (options.onClick && notification) {
        notification.onclick = options.onClick;
      }

      const hideNotification = () => {
        notification.close();
      };

      if (autoClose) {
        setTimeout(hideNotification, autoClose);
      }

      callback(null, hideNotification);
    };

    const { serviceWorkerRegistration, tag } = options;
    if (serviceWorkerRegistration) {
      delete options.serviceWorkerRegistration;

      if (!tag) {
        WebNotification.tagCounter++;
        options.tag = `webnotification-${Date.now()}-${WebNotification.tagCounter}`;
      }

      serviceWorkerRegistration
        .showNotification(title, options)
        .then(() => {
          serviceWorkerRegistration
            .getNotifications({ tag })
            .then((notifications) => {
              if (notifications && notifications.length) {
                onNotification(notifications[0]);
              } else {
                callback(new Error('Unable to find notification.'));
              }
            })
            .catch(callback);
        })
        .catch(callback);
    } else {
      let instance;
      try {
        instance = WebNotification.createInstance(title, options);
      } catch (error) {
        callback(error);
      }

      if (instance) {
        onNotification(instance);
      }
    }
  }

  static parseInput(argumentsArray) {
    let callback = () => {};
    if (argumentsArray.length && typeof argumentsArray[argumentsArray.length - 1] === 'function') {
      callback = argumentsArray.pop();
    }

    let [title, options] = argumentsArray;
    if (argumentsArray.length === 1) {
      if (typeof title === 'string') {
        options = {};
      } else {
        title = '';
        options = title;
      }
    }

    title = title || '';
    options = options || {};

    return {
      callback,
      title,
      options,
    };
  }

  static createInstance(title, options) {
    if (typeof this.lib === 'function') {
      return new this.lib(title, options);
    }else if(window.Notification || global.Notification){
      this.lib = window.Notification || global.Notification
      return new this.lib(title, options);
    } else {
      throw new Error('Notification API is not available.');
    }
  }

  requestPermission(callback) {
    if (callback && typeof callback === 'function') {
      if (WebNotification.isEnabled()) {
        callback(true);
      } else if (this.lib && this.lib.requestPermission) {
        this.lib.requestPermission().then((permission) => {
          callback(permission === 'granted');
        });
      } else {
        callback(false);
      }
    }
}

  showNotification(...args) {
    const argumentsArray = [...args];

    if (argumentsArray.length >= 1 && argumentsArray.length <= 3) {
      const data = WebNotification.parseInput(argumentsArray);
      const { callback, title, options } = data;

      this.requestPermission((granted) => {
        if (granted) {
          // console.log(options);
          WebNotification.createAndDisplayNotification(title, options, callback);
        } else {
          callback(new Error('Notifications are not enabled.'), null);
        }
      });
    }
  }
}

export default WebNotification;
